module.exports = [
    {
        locale: "it",
        label: "Italian",
        default: true,
    },
    {
        locale: "en",
        label: "English",
    },
];
