import React, { useEffect } from "react";
import detectLocale from "./detectLocale";

const Redirect = ({ navigate, pageContext: { redirectPage } }) => {
    useEffect(() => {
       /* console.log('redirect')*/
        const detected = detectLocale();
       /* console.log('detected =>', detected)
        console.log('redirectPage => ', redirectPage)*/
        let newUrl = ''
        if(!redirectPage){
            newUrl = `/${detected}/404`;
        }else{
            newUrl = `/${detected}${redirectPage}`;
        }
        localStorage.setItem("language", detected);
       /* console.log('newUrl => ', newUrl)*/
        navigate(newUrl);
    }, []);

    return <div />;
};

export default Redirect;
